@import url(https://fonts.googleapis.com/css?family=Open+Sans|Poppins:400,500,600&display=swap);
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 2em; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: \201C \201D \2018 \2019; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], input[disabled] {
  cursor: default; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body, figure {
  margin: 0; }

legend, button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: '';
  clear: both;
  height: 0; }

* {
  box-sizing: border-box; }

.visuallyHidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  clip: rect(0 0 0);
  overflow: hidden; }

html {
  font-size: 125%;
  font-family: "Open Sans", sans-serif;
  color: #333; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", sans-serif; }

h1 {
  font-weight: 600; }

h2, h3, h4, h5, h6 {
  font-weight: 500; }

ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

@media (max-width: 600px) {
  html {
    font-size: 145%; } }

html, body, #root, .app {
  height: 100%; }

.app {
  display: flex; }

.wrapper {
  width: 90%;
  max-width: 60rem;
  margin: 0 auto; }

.fullScreenToggle {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 50;
  cursor: pointer; }

header {
  width: 30%;
  height: 100%;
  padding: 1rem 0;
  position: absolute;
  z-index: 20;
  left: 0;
  -webkit-transition: left 0.5s;
  transition: left 0.5s;
  border-right: 0.05rem solid #ddd;
  background: ghostwhite; }

.headerWrapper {
  display: flex;
  flex-direction: column;
  height: 100%; }

.collapsed {
  left: -30%; }

h1 {
  margin: 0;
  margin-bottom: 0.2rem;
  font-size: 2rem;
  line-height: 3rem; }

h1 a, h1 a:visited {
  text-decoration: none;
  color: #333; }

h1 a:hover, h1 a:focus {
  outline: 0;
  color: #008100; }

main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  margin-left: 30%;
  -webkit-transition: margin-left 0.5s;
  transition: margin-left 0.5s;
  overflow-y: scroll;
  overflow-x: hidden; }

.fullScreen {
  margin-left: 0; }

@media (max-width: 768px) {
  header {
    width: 40%; }
  .collapsed {
    left: -40%; }
  main {
    margin-left: 40%; } }

@media (max-width: 600px) {
  header {
    width: 100%; }
  .collapsed {
    left: -100%; }
  main {
    margin-left: 0; } }

.saveStatus, .publishContainer {
  margin: 0.5rem 0;
  padding-left: 0.5rem;
  font-size: 0.8rem; }

.publishContainer {
  margin-bottom: 0; }

.publishLink {
  color: #008100; }

.publishLink:hover, .publishLink:focus, .publishLink:visited {
  color: #4caf50; }

.saved {
  color: #4caf50; }

.titleInput {
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding-left: 0.5rem; }

.textInput {
  padding: 0;
  font-size: 0.8rem; }

.editor-toolbar {
  border: none; }

.CodeMirror {
  border-radius: 0.2rem; }

.authContainer {
  margin-bottom: 0.5rem; }

.authStatus {
  font-size: 0.8rem;
  color: #999;
  margin: 0.5rem 0 0; }

.login {
  color: #4caf50 !important;
  border: 0.05rem solid #4caf50 !important; }

.logout {
  color: #999 !important;
  border: 0.05rem solid #999 !important; }

.notesList {
  width: 110%;
  position: relative;
  left: -5%;
  flex: 1 1 auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: column; }

.notesListHeader {
  margin: 0 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.notesListHeader h2 {
  margin: 0.5rem 0;
  font-size: 1rem;
  line-height: 1rem; }

.addNote {
  outline: 0;
  color: #4caf50;
  cursor: pointer;
  margin-left: 0.5rem; }

.addNote:hover, .addNote:focus {
  color: #008100; }

.toggleView {
  width: 100%;
  margin: 0.2rem 0 0.5rem; }
  .toggleView a {
    outline: 0;
    text-decoration: none;
    color: #4caf50;
    font-size: 0.7rem !important;
    display: flex;
    align-items: center; }
  .toggleView svg {
    margin-right: 0.1rem; }

.toggleView a:hover, .toggleView a:focus {
  color: #008100; }

.notesList ul {
  border-radius: 0.2rem;
  flex: 1 1 auto;
  overflow-y: auto; }

.selected {
  background: #4caf50 !important;
  color: white; }

.selected .deleteNote {
  color: white; }

.note {
  padding: 0.3rem 0.35rem;
  margin: 0.05rem 0;
  position: relative;
  background: white;
  border-radius: 0.2rem; }

.note:hover, .note:focus {
  outline: 0;
  background: #eee; }

.noteLink {
  text-decoration: none;
  color: #333; }

.note::after {
  content: "";
  position: absolute;
  left: 5%;
  bottom: -0.05rem;
  width: 90%;
  height: 0.05rem;
  background: #ddd; }

.noteTitle {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.08rem; }

.note p {
  margin: 0.1rem 0; }

.noteText {
  font-size: 0.72rem; }

.noteCreateDate {
  font-size: 0.65rem; }

.deleteNote {
  position: absolute;
  z-index: 10;
  top: calc(50% - 0.6rem);
  right: 0.3rem;
  font-size: 1.2rem !important;
  color: #999; }

.deleteNote:hover, .deleteNote:focus {
  cursor: pointer;
  color: red; }

.liveIcon {
  margin-right: 0.2rem;
  padding-left: 0.2rem;
  border-radius: 0.2rem;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  background: #008100; }

.publicFeedIntro {
  background: #eee;
  padding: 1rem;
  border-radius: 0.2rem;
  overflow-wrap: break-word; }

.publicFeedIntro p {
  color: #999; }

.feedLink {
  color: #008100; }

.feedLink:hover, .feedLink:focus, .feedLink:visited {
  color: #4caf50; }

.publicPost {
  margin-top: 2rem; }

.publicPost .postTitle {
  padding-left: 1.5rem;
  font-size: 2rem;
  position: relative; }

.publicPost .postTitle::after {
  content: "";
  position: absolute;
  left: 0rem;
  top: 0;
  height: 100%;
  width: 0.9rem;
  background: rgba(76, 175, 80, 0.5); }

