// NOTE: This styles both the NotesList AND PublicList components!
// These component appear in the sidebar!


// Styling for log in and log out section of sidebar
.authContainer {
  margin-bottom: 0.5rem;
}

.authStatus {
  font-size: 0.8rem;
  color: $fadedColorVeryDark;
  margin: 0.5rem 0 0;
}

// Here I am overriding the colours of the Material UI buttons in .login and .logout
// Not the best solution, so I know I'll need to revisit this when I have more time
.login {
  color: $accentColorDark !important;
  border: 0.05rem solid $accentColorDark !important;
}

.logout {
  color: $fadedColorVeryDark !important;
  border: 0.05rem solid $fadedColorVeryDark !important;
}

// Styling for list of notes in sidebar
.notesList {
  // These styles override the wrapper
  width: 110%;
  position: relative;
  left: -5%;

  flex: 1 1 auto; // flex automatically shrinks and grows the list to fit the remaining space!
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.notesListHeader {
  margin: 0 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.notesListHeader h2 {
  margin: 0.5rem 0;
  font-size: 1rem;
  line-height: 1rem;
}

.addNote {
  outline: 0;
  color: $accentColorDark;
  cursor: pointer;
  margin-left: 0.5rem;
}

.addNote:hover, .addNote:focus {
  color: $accentColorVeryDark;
}

.toggleView {
  width: 100%;
  margin: 0.2rem 0 0.5rem;
  
  a {
    outline: 0;
    text-decoration: none;
    color: $accentColorDark;
    font-size: 0.7rem !important; // overriding Material UI again...
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 0.1rem;
  }
}

.toggleView a:hover, .toggleView a:focus {
  color: $accentColorVeryDark;
}

.notesList ul {
  border-radius: 0.2rem;
  flex: 1 1 auto; // flex automatically shrinks and grows the list to fit the remaining space!
  overflow-y: auto; // conditionally display scrollbar
}

.selected {
  background: $accentColorDark !important; // another Material UI style override
  color: white;
}

.selected .deleteNote {
  color: white;
}

.note {
  padding: 0.3rem 0.35rem;
  margin: 0.05rem 0;
  position: relative;
  background: white;
  border-radius: 0.2rem;
}

.note:hover, .note:focus {
  outline: 0;
  background: $fadedColorLight;
}

.noteLink {
  text-decoration: none;
  color: $textColor;
}

.note::after {
  content: "";
  position: absolute;
  left: 5%;
  bottom: -0.05rem;
  width: 90%;
  height: 0.05rem;
  background: $fadedColorDark;
}

.noteTitle {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.08rem;
}

.note p {
  margin: 0.1rem 0;
}

.noteText {
  font-size: 0.72rem;
}

.noteCreateDate {
  font-size: 0.65rem;
}

.deleteNote {
  position: absolute;
  z-index: 10;
  top: calc(50% - 0.6rem);
  right: 0.3rem;
  font-size: 1.2rem !important; // another Material UI style override
  color: $fadedColorVeryDark;
}

.deleteNote:hover, .deleteNote:focus {
  cursor: pointer;
  color: red;
}

.liveIcon {
  margin-right: 0.2rem;
  padding-left: 0.2rem;
  border-radius: 0.2rem;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  background: $accentColorVeryDark;
}