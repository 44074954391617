// Google fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Poppins:400,500,600&display=swap');

// Text content
html {
    font-size: 125%;
    font-family: $textFont;
    color: $textColor;
  }

h1, h2, h3, h4, h5, h6 {
  font-family: $headingFont;
}

h1 {
  font-weight: 600;
}

h2, h3, h4, h5, h6 {
  font-weight: 500;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 600px) {
  html {
    font-size: 145%;
  }
}