// Tools import
@import "partials/setup";
@import "partials/variables";
@import "partials/typography";

// App-wide containers
html, body, #root, .app {
  height: 100%;
}

.app {
  display: flex;
}

.wrapper {
  width: 90%;
  max-width: 60rem; // usually 1200px
  margin: 0 auto;
}

// Full screen button
.fullScreenToggle {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 50;
  cursor: pointer;
}

// Header
header {
  width: 30%;
  height: 100%;
  padding: 1rem 0;
  position: absolute;
  z-index: 20;
  left: 0;
  transition: left 0.5s;
  border-right: 0.05rem solid $fadedColorDark;
  background: ghostwhite;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.collapsed {
  left: -30%;
}

h1 {
  margin: 0;
  margin-bottom: 0.2rem;
  font-size: 2rem;
  line-height: 3rem;
}

h1 a, h1 a:visited {
  text-decoration: none;
  color: $textColor;
}

h1 a:hover, h1 a:focus {
  outline: 0;
  color: $accentColorVeryDark;
}

// Main
main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  margin-left: 30%;
  transition: margin-left 0.5s;
  overflow-y: scroll;
  overflow-x: hidden;
}

.fullScreen {
  margin-left: 0;
}

// Media queries for header and main space distribution
@media (max-width: 768px) {
  header {
    width: 40%;
  }

  .collapsed {
    left: -40%;
  }

  main {
    margin-left: 40%;
  }
}

@media (max-width: 600px) {
  header {
    width: 100%;
  }

  .collapsed {
    left: -100%;
  }

  main {
    margin-left: 0;
  }
}

// Component partial styles
@import "partials/Editor";
@import "partials/NotesList";
@import "partials/PublicPost";