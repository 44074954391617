// Fonts
$headingFont: "Poppins", sans-serif;
$textFont: "Open Sans", sans-serif;;

// Colours
$textColor: #333;
$fadedColorVeryDark: #999;
$fadedColorDark: #ddd;
$fadedColorLight: #eee;
$accentColorDark: #4caf50;
$accentColorVeryDark: rgb(0, 129, 0);