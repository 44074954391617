// Styles for editor component

// Styles the save and publish information at the top of the editor
.saveStatus, .publishContainer {
  margin: 0.5rem 0;
  padding-left: 0.5rem;
  font-size: 0.8rem;
}

.publishContainer {
  margin-bottom: 0;
}

.publishLink {
  color: $accentColorVeryDark;
}

.publishLink:hover, .publishLink:focus, .publishLink:visited {
  color: $accentColorDark;
}

.saved {
  color: $accentColorDark;
}

// Styles the text and textarea inputs
.titleInput {
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  font-size: 2rem;
  font-family: $headingFont;
  font-weight: 500;
  padding-left: 0.5rem;
}

.textInput {
  padding: 0;
  font-size: 0.8rem;
}

// SimpleMDE style overrides
.editor-toolbar {
  border: none;
}

.CodeMirror {
  border-radius: 0.2rem;
}