// Styles for public feed view in main

// Styles top section describing the public feed
.publicFeedIntro {
  background: $fadedColorLight;
  padding: 1rem;
  border-radius: 0.2rem;
  overflow-wrap: break-word;
}

.publicFeedIntro p {
  color : $fadedColorVeryDark;
}

.feedLink {
  color: $accentColorVeryDark;
}

.feedLink:hover, .feedLink:focus, .feedLink:visited {
  color: $accentColorDark;
}

// Styles for post content
.publicPost {
  margin-top: 2rem;
}

.publicPost .postTitle {
  padding-left: 1.5rem;
  font-size: 2rem;
  position: relative;
}

.publicPost .postTitle::after {
  content: "";
  position: absolute;
  left: 0rem;
  top: 0;
  height: 100%;
  width: 0.9rem;
  background: adjust-color($accentColorDark, $alpha: -0.5);
}